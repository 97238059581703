body {
  margin: 0;
}


::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

::-webkit-scrollbar-track:horizontal {
  background-color: #f1f1f1;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px;
}
